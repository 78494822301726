 @import '../../../styles/helpers';

.techStack {
  width: 100vw;
  padding: 100px 0;

  @include media(landscape) {
    padding: 120px 0;
  }

  @include media(desktopAll) {
    padding: 120px 0;
  }

  &_homepage {

    .techStack__content {

      @include media(landscape) {
        padding-right: 120px;
      }

      @include media(desktopAll) {
        padding-right: 140px;
      }

      @include media(desktopSmall) {
        padding-right: 120px;
      }

      @include media(desktop) {
        padding-right: 100px;
      }
    }
  }

  @include media(desktopHuge) {
    padding: 160px 0;
  }

  @include media(portrait) {
    padding: 100px 0;
  }

  @include media(mobile) {
    padding: 100px 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include media(tablet) {
      display: grid;
      gap: 32px;
    }

    @include media(mobile) {
      display: grid;
      gap: 32px;
    }
  }

  &__text {
    max-width: 477px;
    @include setProperty(font-weight, 300, 500);
    font-size: 14px;
    line-height: 2;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));

    @include media(desktopHuge) {
      font-size: 20px;
      max-width: 763px;
    }

    @include media(landscape) {
      max-width: none;
    }

    @include media(portrait) {
      max-width: 508px;
    }
  }

  @keyframes elasticus {
    0% {
      transform-origin: 0 0;
      transform: scale(0, 1);
    }

    100% {
      transform-origin: 0 0;
      transform: scale(1, 1);
    }
  }

  &__line {
    height: 1px;
    width: 100vw;
    @include setProperty(background, var(--dark-line-low-accent), var(--secondary-100));
    transform: scale(0, 1);

    &_animate {
      animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1);
      transform: scale(1, 1);
    }
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding-top: 24px;

  &__wrap {
    margin-top: 164px;

    @include media(portrait) {
      margin-top: 120px;
    }

    @include media(mobile) {
      margin-top: var(--margin-xxl);
    }
  }

  @include media(portrait) {
    grid-template-columns: 1fr;
    border: none;
    padding: 0;
    gap: 56px;
  }

  @include media(mobile) {
    grid-template-columns: 1fr;
    border: none;
    padding: 0;
    gap: 40px;
  }
}

.column {
  text-align: center;
  @include setProperty(opacity, 0.6, 0.8);

  @include media(portrait) {
    text-align: left;
    @include setProperty(opacity, 1, 0.8);
  }

  @include media(mobile) {
    text-align: left;
    @include setProperty(opacity, 1, 0.8);
  }

  &:hover {
    opacity: 1;

    .item {

      &__title {
        margin-top: 0;
        transition: all 0.2s ease;
      }
    }
  }

  &__title {
    margin-bottom: 40px;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    font-size: 14px;
    line-height: 24px;

    @include media(desktopHuge) {
      font-size: 20px;
      line-height: 32px;
    }

    @include media(portrait) {
      font-size: 32px;
      margin-bottom: 0;
    }

    @include media(mobile) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  &__content {
    @include scrollOutOfContainer;
    @include hideScrollbar;

    @include media(portrait) {
      width: 100vw;
      gap: 32px;
    }

    @include media(mobile) {
      width: 100vw;
      gap: 32px;
    }

    @include media(landscape) {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &__technologies {
    @include scrollOutOfContainer;
    @include hideScrollbar;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    transition: margin 0.6s ease;

    &.active {
      transition: margin 0.3s ease;
    }

    @include media(mobile) {
      margin-top: -100%;
      flex-direction: row;
      justify-content: flex-start;
      padding-top: var(--margin-md);

      &.active {
        margin-top: 0;
      }
    }

    @include media(portrait) {
      margin-top: -100%;
      flex-direction: row;
      justify-content: flex-start;
      padding-top: var(--margin-lg);

      &.active {
        margin-top: 0;
      }
    }
  }
}

button.column__title {
  position: relative;
  width: 100%;
  @include setProperty(color, var(--secondary-200), var(--secondary-600));
  transition: all 0.3s ease;

  .dropdownIcon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;

    @include media(portrait) {
      width: 24px;
      height: 24px;
    }
  }

  &.active {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    opacity: 1;

    .dropdownIcon {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &.allNotActive {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    opacity: 1;
  }
}

.item {

  @include media(portrait) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  @include media(mobile) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__image {
    height: 32px;
    width: 32px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media(desktopHuge) {
      height: 40px !important;
      width: 40px !important;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__titleWrap {
    margin-top: 12px;

    @include media(mobile) {
      margin-top: 0;
      margin-left: 9px;
    }

    @include media(portrait) {
      margin-top: 0;
      margin-left: 9px;
    }

    @include media(landscape) {
      overflow: hidden;
      height: 14px;
    }

    @include media(desktopAll) {
      overflow: hidden;
      height: 14px;
    }

  }

  &__title {
    font-size: 12px;
    line-height: 14px;
    transition: all 0.5s ease;

    @include media(landscape) {
      margin-top: -100%;
    }

    @include media(desktopAll) {
      margin-top: -100%;
    }
  }
}

.title {
  font-family: 'KharkivTone', sans-serif;
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 64px;

  @include media(desktopAll) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 96px;
  }
}

.dropdownsList {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__item {
      position: relative;

      &__content {
          &__title {
              @include setProperty(color, var(--secondary-200), var(--secondary-400));
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;

              &_active {
                  @include setProperty(color, var(--primary-500), var(--primary-900));
              }
          }

          &__description {
              display: flex;
              flex-direction: row;
              gap: 16px;
              @include hideScrollbar;
              @include scrollOutOfContainer;
              padding-top: 24px;
              padding-bottom: 16px;
              display: none;

              &_show {
                  display: flex;
              }

              &__item {
                  display: flex;
                  flex-direction: row;
                  gap: 8px;
                  height: 32px;
                  width: fit-content;
                  white-space: nowrap;
                  justify-content: center;
                  align-items: center;

                  &__icon {
                      position: relative;
                      width: 24px;
                      height: 24px;
                      flex-shrink: 0;
                      @include setProperty(color, var(--secondary-50), var(--secondary-500));
                  }

                  &__title {
                      @include setProperty(color, var(--secondary-100), var(--secondary-500));
                      font-size: 12px;
                      font-weight: 300;
                      line-height: 14px;
                  }
              }
          }
      }

      &__icon {
          position: absolute;
          top: 4px;
          right: 0;
          width: 16px;
          height: 16px;
          @include setProperty(color, var(--secondary-200), var(--secondary-400));
          transition: transform 0.3s ease;

          &_active {
              transform: rotate(180deg);
              @include setProperty(color, var(--primary-500), var(--primary-900));
          }
      }
  }
}

.tabsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: scroll;
  @include hideScrollbar;
  @include scrollOutOfContainer;

  &__tabButton {
      @include setProperty(color, var(--secondary-50), var(--secondary-400));
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;

      &_active {
          @include setProperty(color, var(--primary-500), var(--primary-900));
      }
  }

  @include media(portrait) {
      flex-direction: row;
      margin-bottom: 62.74px;

      &__tabButton {
          text-align: center;
          min-width: 89px;
          padding-bottom: 7.26px;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          border-bottom: 2px solid transparent;

          &_active {
              border-bottom: 2px solid;
              @include setProperty(border-color, var(--primary-500), var(--primary-900));
          }
      }
  }

  @include media(landscape) {
      flex-direction: row;
      margin-bottom: 62.74px;

      &__tabButton {
          text-align: center;
          min-width: 89px;
          padding-bottom: 7.26px;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          border-bottom: 2px solid transparent;

          &_active {
              border-bottom: 2px solid;
              @include setProperty(border-color, var(--primary-500), var(--primary-900));
          }
      }
  }

  @include media(desktopAll) {
      flex-direction: row;
      margin-bottom: 58px;

      &__tabButton {
          text-align: center;
          min-width: 109px;
          padding-bottom: 12px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          border-bottom: 2px solid transparent;

          &_active {
              border-bottom: 2px solid;
              @include setProperty(border-color, var(--primary-500), var(--primary-900));
          }
      }
  }

  @include media(desktopBig) {
      &__tabButton {
          min-width: 139px;
      }
  }

  @include media(desktopHuge) {
      &__tabButton {
          min-width: 139px;
      }
  }
}

.contentContainer {
  &__descriptionPart {
      &__title {
          @include setProperty(color, var(--secondary-50), var(--secondary-500));
      }

      &__description {
          @include setProperty(color, var(--secondary-100), var(--secondary-400));
      }
  }

  @include media(portrait) {
      display: flex;
      flex-direction: row;
      gap: 64px;

      &__iconsGrid {
          width: 378px;
          display: grid;
          gap: 24px;
          grid-template-columns: 1fr 1fr 1fr;

          &__iconItem {
              width: 110px;
              height: 74px;
              display: flex;
              flex-direction: column;
              gap: 12px;
              justify-content: center;
              align-items: center;

              &:hover &__icon svg path {
                @include setProperty(fill, var(--primary-500), var(--primary-900));
              }

              &.active &__icon svg path {
                @include setProperty(fill, var(--primary-500), var(--primary-900));
              }

              &__icon {
                  width: 32px !important;
                  height: 32px !important;
                  @include setProperty(color, var(--secondary-50), var(--secondary-500));
              }

              &__text {
                  @include setProperty(color, var(--secondary-100), var(--secondary-500));
                  font-size: 12px;
                  font-weight: 300;
                  line-height: 14px;
              }
          }
      }

      &__descriptionPart {
          padding: 24px 0;
          display: flex;
          flex-direction: column;
          gap: 16px;

          &__title {
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
          }

          &__description {
              font-size: 14px;
              font-weight: 400;
              line-height: 28px;
          }
      }
  }

  @include media(landscape) {
      display: flex;
      flex-direction: row;
      gap: 110px;

      &__iconsGrid {
          width: 378px;
          display: grid;
          gap: 24px;
          grid-template-columns: 1fr 1fr 1fr;

          &__iconItem {
              width: 110px;
              height: 74px;
              display: flex;
              flex-direction: column;
              gap: 12px;
              justify-content: center;
              align-items: center;

              &:hover &__icon svg path {
                @include setProperty(fill, var(--primary-500), var(--primary-900));
              }

              &.active &__icon svg path {
                @include setProperty(fill, var(--primary-500), var(--primary-900));
              }

              &__icon {
                  width: 32px !important;
                  height: 32px !important;
                  @include setProperty(color, var(--secondary-50), var(--secondary-500));
              }

              &__text {
                  @include setProperty(color, var(--secondary-100), var(--secondary-500));
                  font-size: 12px;
                  font-weight: 300;
                  line-height: 14px;
              }
          }
      }

      &__descriptionPart {
          padding: 24px 0;
          display: flex;
          flex-direction: column;
          gap: 16px;

          &__title {
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
          }

          &__description {
              font-size: 14px;
              font-weight: 400;
              line-height: 28px;
          }
      }
  }

  @include media(desktopSmall) {
      display: flex;
      flex-direction: row;
      gap: 112px;

      &__iconsGrid {
          width: 526px;
          display: grid;
          gap: 32px;
          grid-template-columns: 1fr 1fr 1fr 1fr;

          &__iconItem {
              width: 110px;
              height: 74px;
              display: flex;
              flex-direction: column;
              gap: 12px;
              justify-content: center;
              align-items: center;

              &:hover &__icon svg path {
                @include setProperty(fill, var(--primary-500), var(--primary-900));
              }

              &.active &__icon svg path {
                @include setProperty(fill, var(--primary-500), var(--primary-900));
              }

              &__icon {
                  width: 32px !important;
                  height: 32px !important;
                  @include setProperty(color, var(--secondary-50), var(--secondary-500));
              }

              &__text {
                  @include setProperty(color, var(--secondary-100), var(--secondary-500));
                  font-size: 12px;
                  font-weight: 300;
                  line-height: 14px;
              }
          }
      }

      &__descriptionPart {
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 16px;

          &__title {
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
          }

          &__description {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.183px;
          }
      }
  }

  @include media(desktop) {
      display: flex;
      flex-direction: row;
      gap: 112px;

      &__iconsGrid {
          width: 526px;
          display: grid;
          gap: 32px;
          grid-template-columns: 1fr 1fr 1fr 1fr;

          &__iconItem {
              width: 110px;
              height: 74px;
              display: flex;
              flex-direction: column;
              gap: 12px;
              justify-content: center;
              align-items: center;

              &:hover &__icon svg path {
                @include setProperty(fill, var(--primary-500), var(--primary-900));
              }

              &.active &__icon svg path {
                @include setProperty(fill, var(--primary-500), var(--primary-900));
              }

              &__icon {
                  width: 32px !important;
                  height: 32px !important;
                  @include setProperty(color, var(--secondary-50), var(--secondary-500));
              }

              &__text {
                  @include setProperty(color, var(--secondary-100), var(--secondary-500));
                  font-size: 12px;
                  font-weight: 300;
                  line-height: 14px;
              }
          }
      }

      &__descriptionPart {
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 16px;

          &__title {
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
          }

          &__description {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.183px;
          }
      }
  }

  @include media(desktopBig) {
      display: flex;
      flex-direction: row;
      gap: 150px;

      &__iconsGrid {
          width: 526px;
          display: grid;
          gap: 32px;
          grid-template-columns: 1fr 1fr 1fr 1fr;

          &__iconItem {
              width: 110px;
              height: 74px;
              display: flex;
              flex-direction: column;
              gap: 12px;
              justify-content: center;
              align-items: center;

              &:hover &__icon svg path {
                @include setProperty(fill, var(--primary-500), var(--primary-900));
              }

              &.active &__icon svg path {
                @include setProperty(fill, var(--primary-500), var(--primary-900));
              }

              &__icon {
                  width: 32px !important;
                  height: 32px !important;
                  @include setProperty(color, var(--secondary-50), var(--secondary-500));
              }

              &__text {
                  @include setProperty(color, var(--secondary-100), var(--secondary-500));
                  font-size: 12px;
                  font-weight: 300;
                  line-height: 14px;
              }
          }
      }

      &__descriptionPart {
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 16px;

          &__title {
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
          }

          &__description {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.183px;
          }
      }
  }

  @include media(desktopHuge) {
      display: flex;
      flex-direction: row;
      gap: 150px;

      &__iconsGrid {
          width: 526px;
          display: grid;
          gap: 32px;
          grid-template-columns: 1fr 1fr 1fr 1fr;

          &__iconItem {
              width: 110px;
              height: 74px;
              display: flex;
              flex-direction: column;
              gap: 12px;
              justify-content: center;
              align-items: center;

              &:hover &__icon svg path {
                @include setProperty(fill, var(--primary-500), var(--primary-900));
              }

              &.active &__icon svg path {
                @include setProperty(fill, var(--primary-500), var(--primary-900));
              }

              &__icon {
                  width: 32px !important;
                  height: 32px !important;
                  @include setProperty(color, var(--secondary-50), var(--secondary-500));
              }

              &__text {
                  @include setProperty(color, var(--secondary-100), var(--secondary-500));
                  font-size: 12px;
                  font-weight: 300;
                  line-height: 14px;
              }
          }
      }

      &__descriptionPart {
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 16px;

          &__title {
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
          }

          &__description {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.183px;
          }
      }
  }
}
